import { rem } from 'polished';
import styled from 'styled-components';
import { getMaxPageWidth } from '../../constants';
import { breakpoints } from '../../styles/theme';

export const Background = styled.div`
    background: ${({ theme }) => theme.colors.dark};
    color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.div`
    text-align: center;
    padding: ${rem(30)} 0 ${rem(20)} 0;
    line-height: ${rem(24)};
`;

export const Portals = styled.div`
    display: flex;
    margin: ${rem(20)} 0;
    flex-wrap: wrap;

    @media (min-width: ${breakpoints.md}px) {
        flex-wrap: unset;
        justify-content: space-between;
    }
`;

export const Portal = styled.a`
    flex: 50%;
    text-align: center;

    svg {
        width: ${rem(180)};
    }
`;

export const Social = styled.div`
    margin-top: ${rem(20)};

    @media (min-width: ${breakpoints.sm}px) {
        position: absolute;
        right: 0;
        top: ${rem(25)};
    }
`;

export const Navigation = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    border-bottom: 2px solid ${({ theme }) => theme.colors.border};
    padding-bottom: ${rem(45)};
    margin: 0 5%;

    @media (min-width: ${breakpoints.lg}px) {
        margin: 0;
    }
`;

export const NavigationSection = styled.div`
    display: flex;
    flex: 100%;
    flex-direction: column;
    margin-bottom: ${rem(10)};
    align-items: center;

    @media (min-width: ${breakpoints.xs}px) {
        flex: 30%;
        align-items: unset;
    }

    @media (min-width: ${breakpoints.lg}px) {
        flex: unset;
    }
`;

export const NavigationTitle = styled.span`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    padding-bottom: ${rem(20)};
    text-transform: uppercase;
`;

export const NavigationItem = styled.div`
    cursor: pointer;
    padding-bottom: ${rem(20)};
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: unset;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }
`;

export const NavigationLink = styled.a<{ $withoutMargin?: boolean }>`
    color: ${({ theme }) => theme.colors.white};
    margin-left: ${(props) => (props.$withoutMargin ? 0 : rem(10))};
    text-decoration: unset;

    &:hover {
        opacity: 1;
    }
`;

export const Settings = styled.div`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    flex-direction: column;
    padding-bottom: ${rem(20)};
    position: relative;

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
        align-items: flex-end;
        gap: ${rem(20)};
        padding-bottom: ${rem(10)};
    }
`;

export const SettingLink = styled.a`
    cursor: pointer;
    padding-bottom: ${rem(10)};
    color: ${({ theme }) => theme.colors.white};
    text-decoration: unset;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }

    @media (min-width: ${breakpoints.sm}px) {
        padding-bottom: ${rem(30)};
    }
`;

export const NewsletterSection = styled.div`
    background: ${({ theme }) => theme.colors.darkMedium};
    border-radius: ${rem(12)};
    padding: ${rem(25)} ${rem(20)};

    @media (min-width: ${breakpoints.sm}px) {
        width: 40%;
        min-width: ${rem(350)};
    }
`;

export const NewsletterTitle = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h6};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
`;

export const NewsletterDescription = styled.p`
    line-height: ${rem(20)};
    padding-bottom: ${rem(10)};
`;

export const NewsletterLabel = styled.span`
    line-height: ${rem(20)};

    a {
        color: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
    }
`;
