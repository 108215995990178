import React, { useRef, useState } from 'react';
import { FC } from 'react';
import { Icons } from '@components/Icon';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import { EMAIL_REGEX } from '../../constants';
import { authApi } from '../../services/auth.service';
import ContentTemplate from '../../ui/Content/Content';
import {
    Description,
    ErrorMessage,
    Icon,
    Input,
    InputSection,
    Link,
    Section,
    Social,
    SuccessMessage,
} from './Newsletter.elements';

export interface NewsletterProps {
    isStaticBanner?: boolean;
    renderOnlyInput?: boolean;
}

const NewsletterTemplate: FC<NewsletterProps> = ({ isStaticBanner = false, renderOnlyInput = false }) => {
    const { t } = useTranslations();

    const emailInputRef = useRef<HTMLInputElement>();

    const [hasEmailError, setHasEmailError] = useState(false);
    const [hasSent, setHasSent] = useState(false);

    const renderInput = () => (
        <InputSection>
            <Input
                ref={emailInputRef}
                placeholder={t('homepage.newsletter.placeholder')}
                className={`${hasEmailError ? 'error' : ''}`}
            />
            {hasEmailError && <ErrorMessage>{t('homepage.newsletter.error')}</ErrorMessage>}
            {hasSent && <SuccessMessage>{t('homepage.newsletter.sent')}</SuccessMessage>}
            <ButtonTemplate text={t('homepage.newsletter.button')} onClick={() => handleClick()} />
        </InputSection>
    );

    const handleClick = async () => {
        setHasEmailError(false);
        setHasSent(false);

        if (emailInputRef?.current?.value === '' || !EMAIL_REGEX.test(emailInputRef?.current?.value)) {
            setHasEmailError(true);
            return;
        }

        await authApi.addToNewsletter(emailInputRef?.current?.value);

        setHasSent(true);
    };

    return renderOnlyInput ? (
        renderInput()
    ) : (
        <ContentTemplate withPadding>
            <Section isStaticBanner={isStaticBanner}>
                <Description>{t('homepage.newsletter.title')}</Description>
                <Description
                    isStaticBanner={isStaticBanner}
                    dangerouslySetInnerHTML={{ __html: t('homepage.newsletter.description') }}
                />
                {renderInput()}
                <Social>
                    <Link target="_black" href="https://www.facebook.com/FiremniVzdelavaniStudioW">
                        <Icon isStaticBanner={isStaticBanner} name={Icons.Facebook} />
                    </Link>
                    <Link target="_black" href="https://www.linkedin.com/company/studio-w">
                        <Icon isStaticBanner={isStaticBanner} name={Icons.Linkedin} />
                    </Link>
                    <Link target="_black" href="https://www.youtube.com/@studiow2368">
                        <Icon isStaticBanner={isStaticBanner} name={Icons.Youtube} />
                    </Link>
                </Social>
            </Section>
        </ContentTemplate>
    );
};

export default NewsletterTemplate;
