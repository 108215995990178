import React, { useEffect } from 'react';
import { FC } from 'react';
import { Wrapper } from './Content.element';

export interface ContentProps {
    withPadding?: boolean;
    sidePadding?: number;
    mobileSidePadding?: number;
    id?: string;
}

const ContentTemplate: FC<ContentProps> = ({ children, withPadding = false, sidePadding, mobileSidePadding, id }) => {
    return (
        <Wrapper $withPadding={withPadding} $sidePadding={sidePadding} $mobileSidePadding={mobileSidePadding} id={id}>
            {children}
        </Wrapper>
    );
};

export default ContentTemplate;
