import { ILecturerItem } from './Lecturer';

export interface IRecordItem {
    course: {
        _key: string;
        title: string;
        slug: string;
    };
    lectors: ILecturerItem[];
    tags: {
        title: string;
        color: string;
    }[];
    modules: {
        name: string;
        _key: string;
    }[];
    video: {
        duration: string;
        _key: string;
        frame: string;
    };
}

export interface IRecordItem {
    course: {
        _key: string;
        title: string;
        slug: string;
    };
    lectors: ILecturerItem[];
    tags: {
        title: string;
        color: string;
    }[];
    modules: {
        name: string;
        _key: string;
    }[];
    video: {
        duration: string;
        _key: string;
        frame: string;
    };
}

export interface ITrainingItem {
    attachments: {
        _key: string;
        name: string;
        filename: string;
        mime_type: string;
    }[];
    conference?: {
        _id: string;
        _key: string;
        title: string;
        slug: string;
    };
    event?: {
        _id: string;
        _key: string;
        title: string;
        slug: string;
    };
    course?: {
        _id: string;
        _key: string;
        custom: { company: string };
        title: string;
        state: number;
        slug: string;
        seo: {
            description: string;
            keywords: string;
            title: string;
        };
        program: string;
        instructions: string;
        detail: string;
        experience_description: string;
        static_gallery: string[];
        created_at: Date;
        bonuses: string;
        benefits: string;
        annotation: string;
        published: {
            from: Date;
            to: Date;
        };
        schedule: {
            title: string;
            description: string;
            part: {
                description: string;
                from: Date;
                to: Date;
            }[];
        }[];
    };
    isFavorite: boolean;
    is_favourite: boolean;
    is_favorite: boolean;
    eventDate?: ITrainingItemDate;
    dates?: ITrainingItemDate[];
    lecturers: {
        person: ILecturerItem;
        upcoming: IUpcomingEvent[];
    }[];
    modules: IModuleItem[];
    tags: { title: string; label: boolean; color: string }[];
}

export interface IModuleItem {
    accessible: boolean;
    module: {
        name: string;
        _key: string;
        price_with_vat: number;
    };
}

export interface IUpcomingEvent {
    course: {
        title: string;
    };
    dates: {
        from: Date;
        to: Date;
    };
    venues: {
        city: string;
        name: string;
        street: string;
    }[];
}

export interface ITrainingItemDate {
    accessible: boolean;
    online: boolean;
    isOnline: boolean;
    attachments: {
        name: string;
        _key: string;
    }[];
    date: {
        _key: string;
        from: string;
        to: string;
        state: number;
        visibilityState: number;
    };
    streams: {
        _key: string;
        url: string;
    }[];
    videos: {
        _key: string;
        duration: number;
        timeWatched: number;
        chapters: {
            time: number;
            title: string;
        }[];
        name: string;
        url: string;
        created_at: Date;
    }[];
    venues: {
        city: string;
        name: string;
        _key: string;
        street: string;
        link: string;
    }[];
    modules: {
        _key: string;
        price: number;
    }[];
    lectors?: ILecturerItem[];
    people?: ILecturerItem[];
    tags: { title: string; label: boolean; color: string }[];
}

export enum EventStates {
    NO_VISIBLE = 0,
    VISIBLE = 1,
}

export enum EventDateState {
    AVAILABLE = 0,
    SOLD_OUT = 1,
    CANCELLED = 2,
}

export enum EventTypes {
    TRAINING = '0',
    CONFERENCE = '1',
    ARCHIVE = '2',
}

export enum BannerTypes {
    DESKTOP = 'desktop',
    TABLET = 'tablet',
    MOBILE = 'mobile',
}

export enum FilterViewTypes {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}

export enum TrainingViewTypes {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}

export enum NotificationCategory {
    notice = 'notice',
    futureCourse = 'futureCourse',
    recording = 'recording',
    recommendation = 'recommendation',
    futureCourseReminder = 'futureCourseReminder',
    recordingReminder = 'recordingReminder',
}
