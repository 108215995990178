import React, { useState } from 'react';
import { FC } from 'react';
import { useRouter } from 'next/router';
import { Icons } from '@components/Icon';
import IconTemplate from '@components/Icon';
import NewsletterTemplate from '@components/Newsletter/Newsletter';
import useTranslations from '@hooks/useTranslation';
import { ROUTES } from '../../constants';
import ContentTemplate from '../../ui/Content/Content';
import {
    Background,
    Navigation,
    NavigationItem,
    NavigationLink,
    NavigationSection,
    NavigationTitle,
    NewsletterDescription,
    NewsletterLabel,
    NewsletterSection,
    NewsletterTitle,
    Portal,
    Portals,
    SettingLink,
    Settings,
    Social,
    Title,
} from './Footer.elements';

export interface FooterProps {}

const COMPANY_NAVIGATION_ITEM_KEYS = [
    { title: 'footer.company.about', path: 'https://pp.sk/onas' },
    { title: 'footer.company.centre', path: 'https://pp.sk/onas#sect05' },
    { title: 'footer.company.carrier', path: 'https://pp.sk/onas#sect03' },
    { title: 'footer.company.support', path: 'https://pp.sk/podporujeme' },
    { title: 'footer.company.contact', path: 'https://pp.sk/kontakt' },
];
const LEARNING_NAVIGATION_ITEM_KEYS = [
    { title: 'footer.learning.about', path: ROUTES.ABOUT_US },
    { title: 'footer.learning.subscription', path: ROUTES.SUBSCRIPTION },
    { title: 'footer.learning.training', path: ROUTES.COMPANY_COURSES },
    { title: 'footer.learning.reference', path: `${ROUTES.ABOUT_US}#referencie` },
    { title: 'footer.learning.faq', path: `${ROUTES.ABOUT_US}#faqs` },
    { title: 'footer.learning.contact', path: ROUTES.CONTACT },
    { title: 'footer.learning.blog', path: ROUTES.BLOG },
];

const FooterTemplate: FC<FooterProps> = ({}) => {
    const { t } = useTranslations();
    const router = useRouter();

    const handleRedirect = (path: string) => {
        return router.push(path, undefined, { scroll: true });
    };

    return (
        <Background id="footer">
            <ContentTemplate>
                <Title>{t('footer.title')}</Title>
                <Portals>
                    <Portal href="https://www.danovecentrum.sk" target="_black">
                        <IconTemplate name={Icons.Danove} width={180} height={100} />
                    </Portal>
                    <Portal href="https://www.mzdovecentrum.sk" target="_black">
                        <IconTemplate name={Icons.Mzdove} width={180} height={100} />
                    </Portal>
                    <Portal href="https://www.profivzdelavanie.sk" target="_black">
                        <IconTemplate name={Icons.Profi} width={180} height={100} />
                    </Portal>
                    <Portal href="https://www.vssr.sk" target="_black">
                        <IconTemplate name={Icons.Sprava} width={180} height={100} />
                    </Portal>
                    <Portal href="https://www.bezpecnostvpraxi.sk" target="_black">
                        <IconTemplate name={Icons.Bezpecnost} width={180} height={100} />
                    </Portal>
                </Portals>
                <Navigation>
                    <NavigationSection>
                        <NavigationTitle>{t('footer.company.title')}</NavigationTitle>
                        {COMPANY_NAVIGATION_ITEM_KEYS.map((item, key) => (
                            <NavigationItem key={key}>
                                <NavigationLink $withoutMargin href={item.path} target="_blank">
                                    {t(item.title)}
                                </NavigationLink>
                            </NavigationItem>
                        ))}
                    </NavigationSection>
                    <NavigationSection>
                        <NavigationTitle>{t('footer.learning.title')}</NavigationTitle>
                        {LEARNING_NAVIGATION_ITEM_KEYS.map((item, key) => (
                            <NavigationItem onClick={() => handleRedirect(item.path)} key={key}>
                                {t(item.title)}
                            </NavigationItem>
                        ))}
                    </NavigationSection>
                    <NewsletterSection>
                        <NewsletterTitle>Necháte nám email?</NewsletterTitle>
                        <NewsletterDescription>
                            Pravidelne dostanete informácie o legislatíve, školeniach, konferenciách a výhodných
                            ponukách.
                        </NewsletterDescription>
                        <NewsletterTemplate renderOnlyInput />
                        <NewsletterLabel>
                            Odoslaním formulára súhlasíte s doručovaním{' '}
                            <a target="_blank" onClick={() => router.push(`${ROUTES.GDPR}#marketing`)}>
                                marketingovej komunikácie.
                            </a>
                        </NewsletterLabel>
                    </NewsletterSection>
                </Navigation>
                <Settings>
                    <IconTemplate name={Icons.Poradca} width={180} height={100} />
                    <SettingLink onClick={() => handleRedirect(ROUTES.GDPR)}>{t('footer.privacy')}</SettingLink>
                    <SettingLink
                        onClick={() =>
                            router.push({
                                pathname: router.pathname,
                                query: {
                                    ...router.query,
                                    updateCookies: true,
                                },
                            })
                        }
                    >
                        {t('footer.cookies')}
                    </SettingLink>
                    <SettingLink onClick={() => handleRedirect(ROUTES.CONDITIONS)}>
                        {t('footer.conditions')}
                    </SettingLink>
                    <Social>
                        <NavigationLink href="https://www.instagram.com/poradcapodnikatela" target="_black">
                            <IconTemplate name={Icons.Instagram} width={25} height={25} />
                        </NavigationLink>
                        <NavigationLink
                            href="https://sk.linkedin.com/company/poradca-podnikatela-spol-s-r-o-"
                            target="_black"
                        >
                            <IconTemplate name={Icons.Linkedin} width={25} height={25} />
                        </NavigationLink>
                        <NavigationLink href="https://sk-sk.facebook.com/PoradcaPodnikatela" target="_black">
                            <IconTemplate name={Icons.Facebook} width={25} height={25} />
                        </NavigationLink>
                        <NavigationLink href="https://www.youtube.com/user/PoradcaPodnikatela" target="_black">
                            <IconTemplate name={Icons.Youtube} width={25} height={25} />
                        </NavigationLink>
                    </Social>
                </Settings>
            </ContentTemplate>
        </Background>
    );
};

export default FooterTemplate;
